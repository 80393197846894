<template>
  <div :style="slidePosition" class="z-7 w-100 h-100">
    <div class="position-absolute d-flex flex-column align-items-center justify-content-center z-3 spiralWrapper13"
      style=" width: 160vw; height: 160vw; top:60%; left:50%;transform: translate(-50%, -50%); opacity:0;">
      <img src="images/Slide13/Slide13-lines.svg" class="spiral13c w-100 h-100" width="281" height="188"
        aria-label="Yellow Spiral" />
    </div>
    <div class="position-absolute z-2 rounded-circle rotate13a"
      style=" width: 68vw; height: 68vw; top:60%; left:45%;transform: translate(-50%, -50%);">
      <img src="images/Slide6/dottedLine1.svg" class=" w-100 h-100" width="1000" height="1000"
        aria-label="Yellow Spiral" />
    </div>
    <div class="position-absolute z-2 rounded-circle rotate13b"
      style=" width: 80vw; height: 80vw; top:50%; left:45%;transform: translate(-50%, -50%);">
      <img src="images/Slide6/dottedLine2.svg" class=" w-100 h-100" width="1000" height="1000"
        aria-label="Yellow Spiral" />
    </div>
    <div
      class="position-absolute d-flex flex-column align-items-start justify-content-center rounded-circle circle13 z-4 border-warning"
      style="background: rgb(0,161,237); width: 57.5vw; height: 57.5vw; top:60%; left:50%; padding: 8vw; background: rgb(0,161,237); background: linear-gradient(0deg, rgba(0,161,237,1) 0%, rgba(0,108,169,1) 85%); transform: translate(-50%, -50%) scale(0);border:solid 0.75vh;">
      <h6 class="text-white font-vw-3 fade13" style="opacity:1; ">
        1985
      </h6>
      <h6 class="text-white font-vw-2-5 fade13 my-0" style="opacity:1; ">
        GOING GLOBAL
      </h6>
      <p class="mb-0 mt-3 text13 text-white font-vw-1-25" style="opacity:1; ">
        TRIUMF’s contribution of an 80-metre-long beam transport system to the
        Hadron-Electron Ring Accelerator (HERA) at Germany’s national research
        centre, DESY, marked TRIUMF’s first major international accelerator
        activity. HERA required contributions from 11 countries and established
        the framework for future international cooperation. TRIUMF’s involvement
        opened the door to a bevy of global research collaborations, including
        with the United States, Russia, the European Union, Japan, India, and
        South Korea.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  data() {
    return {
      spiralPercent: 0
    }
  },
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  methods: {
    circle13(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 6000
      })

      animation
        .add({
          targets: ".circle13",
          scale: [0, 1],
          duration: 100,

          delay: (el, i) => 5000 + 30 * i,
          easing: "easeOutCubic"
        })
        .add({
          targets: ".fade13",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 1000 + 30 * i
        })
        .add({
          targets: ".text13",

          translateY: ["35%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine"
        })
      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * 1
      )
    },
    spiralWrapperFade(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 200
      })

      animation
        .add({
          targets: ".spiralWrapper13",
          opacity: [0, 1],
          duration: 600,
          delay: 0,
          easing: "linear"
        })
        .add({
          targets: ".spiralWrapper13",
          opacity: [1, 0],
          duration: 400,
          delay: 0,
          easing: "linear"
        })

      animation.seek(animation.duration * percentage)
    },
    startLoop() {
      anime({
        loop: true,
        targets: ".rotate13a",
        rotate: "-5turn",
        direction: "alternate",
        duration: 80000,
        easing: "linear"
      })
      anime({
        loop: true,
        targets: ".rotate13b",
        rotate: "5turn",
        direction: "alternate",
        duration: 130000,
        easing: "linear"
      })
    }
  },
  mounted() {
    this.startLoop()
  },
  watch: {
    innerPosition() {
      this.circle13(this.innerPosition)
      this.spiralWrapperFade(this.innerPosition)
    }
  }
}
</script>

<style></style>
